<template>
    <div>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" style="display:none" preserveAspectRatio="xMidYMid meet">
            <symbol id="icon-Vehicle-Tara" fill="currentColor" viewBox="0 0 34.18 19.63">
                <g id="Capa_2" data-name="Capa 2">
                <g id="Capa_1-2" data-name="Capa 1">
                    <path d="M27,19.63a3.31,3.31,0,1,1,3.41-3.31A3.37,3.37,0,0,1,27,19.63Zm0-5.12a1.81,1.81,0,1,0,1.91,1.81A1.86,1.86,0,0,0,27,14.51Z"/>
                    <path d="M8.26,19.51a3.31,3.31,0,1,1,0-6.61,3.31,3.31,0,1,1,0,6.61Zm0-5.11a1.81,1.81,0,1,0,0,3.61,1.81,1.81,0,1,0,0-3.61Z"/>
                    <path d="M32.11,16.31a.75.75,0,0,1,0-1.5.57.57,0,0,0,.57-.58V7.08a.49.49,0,0,0,0-.12L31,2a.62.62,0,0,0-.58-.54H22.12a.58.58,0,0,0-.58.58V14.23a.58.58,0,0,0,.58.58.75.75,0,0,1,0,1.5A2.08,2.08,0,0,1,20,14.23V2.08A2.08,2.08,0,0,1,22.12,0h8.25a2.08,2.08,0,0,1,2,1.63l1.72,4.92a1.92,1.92,0,0,1,.07.53v7.15A2.08,2.08,0,0,1,32.11,16.31Z"/>
                    <path d="M2.93,16.31H.75a.75.75,0,1,1,0-1.5H2.93a.75.75,0,1,1,0,1.5Z"/>
                    <path d="M18.42,16.31H13.65a.75.75,0,1,1,0-1.5h4.77a.75.75,0,0,1,0,1.5Z"/>
                    <path d="M30.66,9a.75.75,0,0,1-.72-.52l-1.2-3.93a.28.28,0,0,0-.28-.27H24.94a.75.75,0,0,1,0-1.5h3.52A1.76,1.76,0,0,1,30.23,4.4L31.37,8a.74.74,0,0,1-.49.94A.83.83,0,0,1,30.66,9Z"/>
                </g>
                </g>
            </symbol>
        </svg>
        <ModalTrasport
            :modal.sync="modalActive"
            :item="Incidence"
            @close-Modal="closeModal"
        />
        <CRow class="cardPersonalizado">
            <CCol md="12" lg="12" style="padding:0px;border-right: 1px solid #d8dbe0;">
                <div
                    class="cardHeaderPersonalizado d-flex align-items-center"
                    :style="
                        'background:' + ' #4d5a70 ' + '!important;width:100%;color:#FFFFFF;font-weight:bold;'
                    "
                >
                    
                    <svg class="bi flex-shrink-0 me-2" width="32" height="32" role="img" aria-label="watch:"><use xlink:href="#icon-recursos2"/></svg> <span style="margin-left:5px;"> {{ $t("label.incidence") }}</span>
                </div>
                <CCol lg="12">
                    <CRow>
                        <div class="col-lg-6" style="padding:10px;background: #E8F0F4;">
                            <CRow>
                                <CCol lg="6">
                                    <label class="legend col-lg-12" style="font-weight: bold;">{{$t('label.transport')}}</label>
                                    <label class="legend col-lg-12" style="font-weight: bold;">{{$t('label.TotalIncidenceByTransport')}}</label>
                                </CCol>
                                <CCol lg="6">
                                    <CRow style="display: flex; justify-content: end;">
                                        <div class="col-lg-5">
                                            <svg class="bi flex-shrink-0 me-2 legend" width="100%" height="48" role="img" aria-label="watch:"><use xlink:href="#icon-Vehicle-Tara"/></svg>
                                        </div>
                                        <div class="col-lg-5 px-0">
                                            <h4 class="legend tex-negrita mb-0">{{IncidenceCarrierDetails[0]?.TotalIncidenceTimeH}}</h4>
                                            <h4 class="legend tex-negrita mb-0">HRS</h4>
                                        </div>
                                    </CRow>    
                                </CCol>
                                <CCol lg="12">
                                    <simpleDonut
                                        :Series="SeriesArray" 
                                        :Colors="ColorArray" 
                                        :Categories="LabelsArray"
                                    />
                                </CCol>
                                <CCol lg="12">
                                    <CCol lg="12" v-for="incidence in IncidenceCarrierDetails[0]?.CarrierIncidenceJson" :key="incidence.IdX">
                                        <CRow>
                                            <div class="ml-3" :style="LabelColor(incidence.Color)"></div>
                                            <label style="cursor: pointer" class="legend ml-3" @click="modalTrasport(incidence)">{{incidence.CarrierClientName+': '+incidence.TotalTimeHIncidenceByCarrier}}</label>
                                        </CRow>
                                    </CCol>
                                </CCol>
                            </CRow>
                        </div>
                        <div class="col-lg-6" style="padding:10px;background: #C8D9EB;">
                            <label class="legend" style="font-weight: bold; margin-top:26px;">{{$t('label.TotalByIncidence')}}</label>
                            <CRow>
                                <CCol lg="12">
                                    <SimpleBar
                                        Width="100%"
                                        Height="270px"
                                        :Series="IncidencerSummaryArray"
                                        :Categories="IncidenceType"
                                        :Colors="IncidencerSummaryColorsArray"
                                    >
                                    </SimpleBar>
                                </CCol>
                                <CCol lg="12" v-for="incidence in IncidencerCarrierSummary" :key="incidence.index">
                                    <CCol lg="12">
                                        <CRow>
                                            <CCol lg="6">
                                                <CRow>
                                                    <div class="ml-3" :style="LabelColor(incidence.Incidence1.Color)"></div>
                                                    <label class="legend col-lg-10">{{incidenceName(incidence.Incidence1)}}</label>
                                                </CRow>
                                            </CCol>
                                            <CCol lg="6" v-if="incidence.Incidence2">
                                                <CRow>
                                                    <div class="ml-3" :style="LabelColor(incidence.Incidence2.Color)"></div>
                                                    <label class="legend col-lg-10">{{incidenceName(incidence.Incidence2)}}</label>
                                                </CRow>
                                            </CCol>
                                        </CRow>
                                    </CCol>
                                </CCol>
                            </CRow>
                        </div>
                    </CRow>
                </CCol>
                
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { NumberFormater } from '@/_helpers/funciones';
    import { mapState  } from 'vuex';
    import SimpleBar from "../../components/SimpleBar";
    import ModalTrasport from "./modal-transport";
    import simpleDonut from '../../components/simpleDonut';

    function data() {
        return {
            Incidence: {},
            modalActive: false,
        };
    }
    //methods
    function NumberFormat(number, decimal) {
        return NumberFormater.formatNumber(number, decimal);
    }

    function incidenceName(incidence){
        let _lang = this.$i18n.locale;
        let incidenceName = _lang == 'en' ? incidence.IncidenceNameEn : incidence.IncidenceNameEs;
        return incidenceName;
    }
    
    function LabelColor(Color){
        return { background: Color, width: '30px' };
    }

    function modalTrasport(item){
        this.Incidence = item;
        this.modalActive = true;
    }

    function closeModal(){
        this.modalActive = false;
        this.Incidence = {};
    }
    //computed 
    function flag(){
        if(this.$store.state.situacionOperativaActual.tabsOperationes === 5 && this.$store.state.situacionOperativaActual.carruselTab === 2){
            return true;
        }else{
            return false;
        }
    }

    function SeriesArray(){
        let data = [];
        let CarrierIncidenceJson = this.IncidenceCarrierDetails[0]?.CarrierIncidenceJson?this.IncidenceCarrierDetails[0]?.CarrierIncidenceJson:[];
        CarrierIncidenceJson.map(async(item) => {
            data.push(item.TotalTimeIncidenceByCarrier);
        });
        return data;
    }

    function ColorArray(){
        let data = [];
        let CarrierIncidenceJson = this.IncidenceCarrierDetails[0]?.CarrierIncidenceJson?this.IncidenceCarrierDetails[0]?.CarrierIncidenceJson:[];
        CarrierIncidenceJson.map(async(item) => {
            data.push(item.Color);
        });
        return data;
    }

    function LabelsArray(){
        let data = [];
        let CarrierIncidenceJson = this.IncidenceCarrierDetails[0]?.CarrierIncidenceJson?this.IncidenceCarrierDetails[0]?.CarrierIncidenceJson:[];
        CarrierIncidenceJson.map(async(item) => {
            data.push(item.CarrierClientName);
        })
        return data;
    }

    function IncidencerSummaryColorsArray(){
        let data = [];
        this.IncidenceCarrierSummary.map(async(item) => {
            data.push(item.Color);
        })
        return data;
    }

    function IncidenceType(){
        let data = [];
        let _lang = this.$i18n.locale;
        this.IncidenceCarrierSummary.map(async(item,index) => {
            let incidenceName = _lang == 'en' ? item.IncidenceNameEn : item.IncidenceNameEs;
            incidenceName = incidenceName.split(' ');
            data.push(incidenceName);
        })
        return data;
    }

    function IncidencerSummaryArray(){
        let data = [{
            name: 'HRS',
            data: [],
        }];
        let TotalTime = [];
        for (let index = 0; index < this.IncidenceCarrierSummary.length; index++) {
            TotalTime[index] = this.IncidenceCarrierSummary[index].TotalTime; 
        }
        data[0].data = TotalTime;
        return data;
    }

    function IncidencerCarrierSummary(){
        let data = [];
        for (let index = 0, length = 0; length < (this.IncidenceCarrierSummary.length/2); index = index+2, length++) {
            data.push({
                Incidence1: this.IncidenceCarrierSummary[index],
                Incidence2: this.IncidenceCarrierSummary[index+1],
                index: length,
            });        
        }
        return data;
    }

    export default {
        data,
        props:{
            IncidenceCarrierDetails:{
                type:Array,
                default: () => [],
            },
            IncidenceCarrierSummary:{
                type:Array,
                default: () => [],
            },
        },
        methods:{
            closeModal,
            modalTrasport,
            NumberFormat,
            incidenceName,
            LabelColor,
        },
        components:{
            ModalTrasport,
            SimpleBar,
            simpleDonut,
        },
        computed:{
            IncidencerCarrierSummary,
            flag,
            IncidencerSummaryArray,
            IncidenceType,
            IncidencerSummaryColorsArray,
            SeriesArray,
            ColorArray,
            LabelsArray,
            ...mapState({
                carruselTab : state=> state.situacionOperativaActual.carruselTab,
            })
        }
    }
</script>

<style scoped>
    .legend{
        color: #787674;
    }
    .tex-negrita{
        font-weight: bold;
    }
</style>